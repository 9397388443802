<template lang="pug">
div.mx-8
  h5.text-left.p-0 {{ $t('uploadInternationalCertificateETI') }}
  FileDropZone(
    :error-messages="$valid.validation($v.mediaFilesArray, ['required'])"
    ref="mediaContent").w-100.mt-1.pa-0
  div.mt-4
    v-btn(color="success" @click="uploadFileCertificate" :loading="isLoading" ) {{ $t('btn.save') }}
</template>

<script>
import { mapActions } from 'vuex'
import { sailorStatementFileCertificateISC } from '@/mixins/validationRules'
export default {
  name: 'DownloadCertificateISC',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: { statement: { type: Object, default: () => ({}) } },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    mediaFilesArray () {
      if (!this.statement.is_payed) return this.$refs.mediaContent.filesArray
      else return []
    }
  },
  validations () { return sailorStatementFileCertificateISC() },
  methods: {
    ...mapActions(['setSailorFileCertificateETI']),
    async uploadFileCertificate () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      let data = {
        ...this.$route.params,
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'international_certificate'
        }
      }
      await this.setSailorFileCertificateETI(data)
      this.isLoading = false
    }
  }

}
</script>
